import { ActionTree } from 'vuex';

import axios from 'qs_vuetify/src/plugins/axios';

import { User } from 'qs_vuetify/src/types/models';
import { PassportResponse, ZendeskResponse } from 'qs_vuetify/src/types/responses';
import { AuthState, RootState } from '@/types/states';

const userFields = [
  'id',
  'email',
  'first_login',
  ...['id', 'name'].map((f) => `instances.${f}`),
  ...[
    'address',
    'apartment',
    'birthdate',
    'city',
    'contribution_current_year',
    'country',
    'dpa',
    'district_id',
    'email',
    'emails',
    'first_name',
    'gender',
    'home_phone',
    'last_name',
    'main_language',
    'postal_code',
    'province',
    'user_id',
    'v1_contact_id',
    'work_phone',
    'memberships.*',
    'current_membership.*',
    'district.*',
    'stripe_customer.*',
    'unsubscriptions.*',
    'upcoming_national_event_participations.*',
    'volunteers.instance.id',
    'volunteers.instance.name',
  ].map((f) => `contact.${f}`),
].join(',');

export const actions: ActionTree<AuthState, RootState> = {
  async cancelMembershipRenewal({ commit, dispatch }) {
    commit('error', null);
    commit('loading', true);

    try {
      await axios.delete('/auth/user/contact/stripe_customer/subscription');

      await dispatch('loadUser');
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        commit('error', err.response.data);
      } else {
        const { message } = (err as Error);
        commit('error', {
          message,
          code: 'other',
          status: 500,
        });
      }

      throw err;
    } finally {
      commit('loading', false);
    }
  },

  async changePassword(
    { commit },
    { password, newPassword }: { newPassword: string; password: string },
  ) {
    commit('error', null);
    commit('loading', true);

    try {
      await axios.post(
        '/auth/change_password',
        {
          password,
          new_password: newPassword,
        },
      );
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        commit('error', {
          message: err.message,
          ...err.response.data,
          status: err.response.status,
        });
      } else {
        const { message } = (err as Error);
        commit('error', {
          message,
          code: 'other',
          status: 500,
        });
      }

      throw err;
    } finally {
      commit('loading', false);
    }
  },

  async checkUser({ commit }, fields: 'id') {
    try {
      const ajax = axios.get('/auth/user', { params: { fields } });

      commit('loading', true);

      await ajax;
    } catch (e) {
      commit('token', null);
      commit('refreshToken', null);
      commit('user', null);

      throw e;
    } finally {
      commit('loading', false);
    }
  },

  async confirmParticipation({ commit }, id) {
    commit('error', null);
    commit('loading', true);

    try {
      await axios.put(`/national_events/participations/${id}/confirm`);
    } catch (err) {
      if (!axios.isAxiosError(err)) {
        throw err;
      }

      if (err.response) {
        commit('error', err.response.data);

        commit('global/addNotification', {
          message: err.response?.data?.message || `Une erreur s'est produite: ${err.response.status}`,
          color: 'error',
          timeout: 5000,
          value: true,
        }, { root: true });
      }
    } finally {
      commit('loading', false);
    }
  },

  async connectToMeeting({ commit }, id) {
    commit('error', null);
    commit('loading', true);

    try {
      await axios.put(`/zoom/${id}/sign`);
    } catch (err) {
      if (!axios.isAxiosError(err)) {
        throw err;
      }

      if (err.response) {
        commit('error', err.response.data);

        commit('global/addNotification', {
          message: err.response?.data?.message || `Une erreur s'est produite: ${err.response.status}`,
          color: 'error',
          timeout: 5000,
          value: true,
        }, { root: true });
      }
    } finally {
      commit('loading', false);
    }
  },

  async loadParticipations({ commit, state }) {
    commit('error', null);
    commit('loading', true);

    try {
      const { data } = await axios.get<User>(
        '/auth/user',
        {
          params: {
            fields: 'contact.upcoming_national_event_participations.*',
          },
        },
      );

      commit('user', {
        ...state.user,
        contact: {
          ...state.user?.contact,
          upcoming_national_event_participations: data.contact?.upcoming_national_event_participations ?? [],
        },
      });

      commit('loading', false);
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        commit('error', err.response.data);
      }

      throw err;
    } finally {
      commit('loading', false);
    }
  },

  async loadUser({ commit }, fields: string = userFields) {
    commit('error', null);
    commit('loading', true);

    try {
      const { data: user, headers: { 'x-scopes': scopes } }: { data: User; headers: Record<string, string> } = await axios.get(
        '/auth/user',
        {
          params: {
            fields,
          },
        },
      );

      commit('scopes', scopes.split(','));
      commit('initialItem', JSON.stringify(user));
      commit('user', user);

      commit('loading', false);
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        commit('error', err.response.data);
      }

      throw err;
    } finally {
      commit('loading', false);
    }
  },

  async login(
    { commit },
    { id, email, password }: { id?: string; email?: string; password: string },
  ) {
    commit('error', null);
    commit('loading', true);

    try {
      const { data }: { data: PassportResponse } = await axios.post(
        '/auth/login?fields=*',
        {
          id,
          email,
          password,
        },
      );

      commit('token', data.access_token);
      commit('refreshToken', data.refresh_token);
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        commit('error', err.response.data || err.message);
      } else {
        const { message } = (err as Error);
        commit('error', {
          message,
          code: 'other',
          status: 500,
        });
      }

      throw err;
    } finally {
      commit('loading', false);
    }
  },

  async requestMemberId(
    { commit },
    { email }: { email: string },
  ) {
    commit('error', null);
    commit('loading', true);

    try {
      await axios.post(
        '/auth/request_member_id',
        {
          email,
        },
      );
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        commit('error', err.response.data || err.message);
      } else {
        const { message } = (err as Error);
        commit('error', {
          message,
          code: 'other',
          status: 500,
        });
      }

      throw err;
    } finally {
      commit('loading', false);
    }
  },

  async requestPasswordReset(
    { commit },
    { id, email }: { id?: string; email?: string },
  ) {
    commit('error', null);
    commit('loading', true);

    try {
      await axios.post(
        '/auth/request_password_reset',
        {
          id,
          email,
        },
      );
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        commit('error', err.response.data || err.message);
      } else {
        const { message } = (err as Error);
        commit('error', {
          message,
          code: 'other',
          status: 500,
        });
      }

      throw err;
    } finally {
      commit('loading', false);
    }
  },

  async resetPassword(
    { commit },
    { email, password, token }: { email: string; password: string; token: string },
  ) {
    commit('error', null);
    commit('loading', true);

    try {
      const { data } = await axios.post(
        '/auth/reset_password',
        {
          email,
          password,
          password_confirmation: password,
          token,
        },
      );

      commit('error', data);
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        commit('error', {
          message: err.message,
          ...err.response.data,
          status: err.response.status,
        });
      } else {
        const { message } = (err as Error);
        commit('error', {
          message,
          code: 'other',
          status: 500,
        });
      }

      throw err;
    } finally {
      commit('loading', false);
    }
  },

  async saveUser({ commit }, user) {
    commit('error', null);
    commit('loading', true);

    try {
      const { data }: { data: User } = await axios.post('/auth/user', user, {
        params: {
          fields: userFields,
        },
      });

      commit('user', data);
      commit('global/addNotification', {
        message: 'Profil sauvegardé avec succès.',
        color: 'success',
        value: true,
        timeout: 5000,
      }, { root: true });
    } catch (err) {
      if (!axios.isAxiosError(err)) {
        throw err;
      }

      if (err.response) {
        commit('error', err.response.data);

        if (err.response.status !== 422) {
          commit('global/addNotification', {
            message: err.response?.data?.message || `Une erreur s'est produite: ${err.response.status}`,
            color: 'error',
            timeout: 5000,
            value: true,
          }, { root: true });
        }
      }
    } finally {
      commit('loading', false);
    }
  },

  async zendesk({ commit }, returnTo) {
    commit('error', null);
    commit('loading', true);

    try {
      const { data }: { data: ZendeskResponse } = await axios.post(
        '/auth/zendesk',
        {
          return_to: returnTo,
        },
      );

      document.location.href = data.url;
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        commit('error', err.response.data);
      } else {
        const { message } = (err as Error);
        commit('error', {
          message,
          code: 'other',
          status: 500,
        });
      }

      commit('loading', false);

      throw err;
    }
  },

  async logout({ commit }) {
    try {
      await axios.get('/auth/logout');
    } finally {
      commit('token', null);
      commit('refreshToken', null);
      commit('user', null);

      commit('loading', false);
    }
  },

  async unconfirmParticipation({ commit }, id) {
    commit('error', null);
    commit('loading', true);

    try {
      await axios.put(`/national_events/participations/${id}/unconfirm`);
    } catch (err) {
      if (!axios.isAxiosError(err)) {
        throw err;
      }

      if (err.response) {
        commit('error', err.response.data);

        commit('global/addNotification', {
          message: err.response?.data?.message || `Une erreur s'est produite: ${err.response.status}`,
          color: 'error',
          timeout: 5000,
          value: true,
        }, { root: true });
      }
    } finally {
      commit('loading', false);
    }
  },

  async useActionToken({ commit }, token: string) {
    commit('error', null);
    commit('loading', true);

    try {
      const { data, headers } = await axios.get(`/auth/action/${token}`);

      commit('token', data.access_token);

      if (headers.location) {
        commit('redirect', headers.location);
      }

      const { message } = data;
      if (message) {
        commit('message', message, { root: true });
      }

      commit('loading', false);
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        const { status, data: message } = err.response;
        commit('error', {
          status,
          message,
        });
      }
    } finally {
      commit('loading', false);
    }
  },
};

export default actions;
